// Generated by Framer (9f979fb)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["gHGPMUz0O", "UPMBkmmYx"];

const serializationHash = "framer-4eqvA"

const variantClassNames = {gHGPMUz0O: "framer-v-17d4wr4", UPMBkmmYx: "framer-v-oxfxxa"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0.2, mass: 1, stiffness: 430, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "gHGPMUz0O", "Variant 2": "UPMBkmmYx"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "gHGPMUz0O"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "gHGPMUz0O", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-17d4wr4", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"gHGPMUz0O"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-825dae40-55d4-4fdb-a9a6-4c865968e205, rgb(255, 255, 255))", ...style}} variants={{UPMBkmmYx: {backgroundColor: "var(--token-22e34c00-f547-475f-b85c-d728de6742b6, rgb(28, 28, 28))"}}} {...addPropertyOverrides({UPMBkmmYx: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-4eqvA[data-border=\"true\"]::after, .framer-4eqvA [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-4eqvA.framer-zxpuz5, .framer-4eqvA .framer-zxpuz5 { display: block; }", ".framer-4eqvA.framer-17d4wr4 { height: 1330px; overflow: hidden; position: relative; width: 1200px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1330
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"UPMBkmmYx":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerp1GfolaXZ: React.ComponentType<Props> = withCSS(Component, css, "framer-4eqvA") as typeof Component;
export default Framerp1GfolaXZ;

Framerp1GfolaXZ.displayName = "Elements/ Change background";

Framerp1GfolaXZ.defaultProps = {height: 1330, width: 1200};

addPropertyControls(Framerp1GfolaXZ, {variant: {options: ["gHGPMUz0O", "UPMBkmmYx"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerp1GfolaXZ, [])